import React, { useState, useEffect } from 'react';
import styles from "./styles.module.css";
import { Link } from "react-router-dom";
import * as apiService from '../../services';
import { Modal, Button } from 'react-bootstrap';

const Partners = () => {
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');

    
    const [email, setNewemail] = useState('');
    const [companyList, setCompanyList] = useState('');
    const [data, setData] = useState([]);
    const user = localStorage.getItem("admin_type");

    useEffect(() => {
        localStorage.removeItem("token1");
        fetchData();
        setNewemail(localStorage.getItem("adminemail"));
    }, []);

    const fetchData = async () => {
        const data = await apiService.allcompanylist({ companyList });
        console.log(data.user_data);
        setData(data.user_data);
    };

    const handleLogout = () => {
        localStorage.removeItem("token");
        window.location.reload();
    };

    const handleChangePassword = () => {
        setShowChangePassword(true);
    };

    const handleCloseChangePassword = () => {
        setShowChangePassword(false);
    };

   const [changePasswordmsg, setchangePasswordmsg] = useState('');

    const handlePasswordChange = async () => {
        // Call API to change password
        // Example API call (replace with actual API call):
        try {
            const response = await apiService.changePassword( email, newPassword, oldPassword );
            console.log(response); // Log the response from the API
            setchangePasswordmsg(response.message)
            // You may handle success or error response here
        } catch (error) {
            console.error('Error changing password:', error);
            // Handle error here
        }
        // setShowChangePassword(false); // Close the popup after password change
    };



    

    return (
        <>
            <div className={styles.main_container}>
                <div className="container">
                    <div className="position-relative d-flex flex-column justify-content-center align-items-center py-3 px-2">
                        <div className="partners-page" style={{ width: '40%' }}>
                            <div className="card">
                                <h4 className="card-header text-uppercase">Partners</h4>
                                

                                {/* Change Password Popup */}
                                {showChangePassword && (
                                     <Modal show={showChangePassword} onHide={() => setShowChangePassword(false)} centered>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Change Password</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                    <label>Email</label>
                                     <br/>
                                      <input className="form-control" type="email" readOnly value={email} />
                                      <br/>
                                      <label>Old Password</label>
                                        <br/>
                                        <input className="form-control" type="password" placeholder="Enter Old Password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
                                       <label>New Password</label>
                                        <br/>
                                        <input className="form-control" type="password" placeholder="Enter New Password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />

                                        <span style={{color:"red"}}>{changePasswordmsg}</span>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={() => setShowChangePassword(false)}>Close</Button>
                                        <Button variant="primary" onClick={handlePasswordChange}>Submit</Button>
                                    </Modal.Footer>
                                </Modal>
                                )}

                                <div className="card-body">
                                    {user == 1 &&
                                        <button className="btn btn-primary" type="button">
                                            <Link to="/partners/add-partnerform" style={{ color: "#fff" }}>Add</Link>
                                        </button>

                                    }
                                     <button className="btn btn-primary mx-2" type="button" onClick={handleChangePassword}>Change Password</button>

                                      <button className="btn btn-primary mx-2" type="button">
                                      <Link style={{ color: "#fff" }} to="/landingpage/listassessment">View Assessment List</Link>
                                      </button>

                                     

                                     

                                     {/*<span>{changePasswordmsg}</span>*/}

                                    <div className="mb-3"></div>
                                    <ul className="list-group list-group-flush nav flex-column" id="adminuser">
                                        {data?.map((item) => {
                                            return (
                                                <li className="list-group-item nav-link d-flex align-items-center">
                                                    <img className="rounded-3" src={`https://starbehaviors.com/uploadimage/${item.imageFile}`} style={{ width: "90px", height: "30px" }} alt="TTJ Recruiting Logo" />
                                                    <Link className="nav-link text-decoration-underline stretched-link" to={`partnerdetails/${item.adminemail}`}>
                                                        {item.companyname}
                                                    </Link>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Partners;

import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AssessmentTable = () => {
  const [assessments, setAssessments] = useState([]);
  const [loading, setLoading] = useState(true);

useEffect(() => {
  const fetchData = async () => {
    try {
        const response = await axios.get('https://casaahaanahotels.com/get_emails', {});
        setAssessments(response.data);
        setLoading(false);
      } catch (error) {
        
        setLoading(false);
      }



  };

  fetchData();
}, []);

function sendButtonClickHandler(payload) {
  // Extract values from payload
  const { body, sendto, replyTo, title } = payload;

  console.log(sendto);
  

  // Construct your API call
  const apiUrl = 'https://starbehaviors.com/test.php';
  const requestData = {
    body,
    sendto,
    replyTo,
    title
    // Add any other necessary data for your API call
  };

  // Make the API call using fetch or any other method
  fetch(apiUrl, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
      },
    body: JSON.stringify(requestData)
  })
  .then(response => {
    // Handle response as needed
  })
  .catch(error => {
    // Handle error
    console.error('Error sending data:', error);
  });
}



  return (
    <div>
      <h2 style={{ textAlign: "center" }}>Mail List</h2>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <table>
          <thead>
            <tr>
              
               <th>Reply To</th>
             
              <th>Send to</th>
              <th>Title</th>
             
              <th>Error</th>
              <th>Sand</th>

            </tr>
          </thead>
    <tbody>
  {assessments.map((assessment, index) => (
    <tr key={index}>
      <td>{assessment.payload.replyTo}</td>
      <td>{assessment.payload.sendto}</td>
      <td>{assessment.payload.title}</td>
      <td>{assessment.response.body.error.toString()}</td>
      <td>
        {/* Use an anchor tag to create a link */}
        <button 
          onClick={() => sendButtonClickHandler(assessment.payload)} // Attach click event handler
          data-msg={assessment.payload.body} 
          data-sendto={assessment.payload.sendto} 
          data-replyTo={assessment.payload.replyTo} 
          data-sub={assessment.payload.title}
        >
          Send
        </button>
      </td>
    </tr>
  ))}
</tbody>

        </table>
      )}
    </div>
  );
};

export default AssessmentTable;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const AssessmentTable = () => {
const [assessments, setAssessments] = useState([]);
  const [loading, setLoading] = useState(true);
  const { assessmentId } = useParams(); // Extracting assessmentId from the URL

  useEffect(() => {
    console.log("assessmentId:", assessmentId);
    const fetchData = async () => {
      try {
        const response = await axios.post('https://casaahaanahotels.com/print_assessment', {
          assessmentid: assessmentId // Use assessmentId from URL params
        });
        setAssessments(response.data.result);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [assessmentId]); 

  return (
    <div>
      <h2 style={{textAlign: "center"}}>Assessment Data</h2>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Question (Skill)</th>
              <th>Question (Behaviour)</th>
              <th>Driver Answer</th>
             
              <th>Point</th>
            </tr>
          </thead>
          <tbody>
  {assessments.map((assessment, index) => (
    assessment._source.assessmentquestion.map((question, idx) => {
      let skillColorClass = '';
      let behaviourColorClass = '';

      
      let Question_ans ='';

      if (question.Skills === question.Answer) {
        skillColorClass = '#f2f2f2'; // Change background color for point 1
      } else if (question.Behaviour === question.Answer) {
        behaviourColorClass = '#f2f2f2'; // Change background color for point 5
      }

      if (question.Skills === question.Answer) {
        Question_ans = question.Question_skill; 
      } else if (question.Behaviour === question.Answer) {
        Question_ans = question.Question_behaviour; 
      }

      return (
        <tr key={`${index}-${idx}`} style={{backgroundColor:"#ffffff",borderBottom: "1px solid #ccc"}}>
          <td style={{ background: skillColorClass }}>{question.Question_skill || question.Question_skill}</td>
          <td style={{ background: behaviourColorClass }}>{question.Question_behaviour || question.Question_behaviour}</td>
          <td>{Question_ans}</td>
          <td>{question.Point}</td>
        </tr>
      );
    })
  ))}
</tbody>

        </table>
      )}
    </div>
  );
};

export default AssessmentTable;

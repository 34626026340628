import React, { useState, useEffect } from 'react';
import styles from "./styles.module.css";
import { Link } from "react-router-dom";
import * as apiService from '../../../services';
import { Modal, Button } from 'react-bootstrap';

const Partners = () => {
    const [showChangePassword, setShowChangePassword] = useState(true);
    const [newPassword, setNewPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');

    
    const [email, setEmailPassword] = useState('');
  
   


    const handleLogout = () => {
        localStorage.removeItem("token1");
        window.location.reload();
    };

    const handleChangePassword = () => {
        setShowChangePassword(true);
    };

    const handleCloseChangePassword = () => {
        setShowChangePassword(false);
    };

   const [changePasswordmsg, setchangePasswordmsg] = useState('');
   const [showLoginButton, setShowLoginButton] = useState(false);

    const handlePasswordChange = async () => {
        // Call API to change password
        // Example API call (replace with actual API call):
        try {
            const response = await apiService.changeDriverPassword( email, newPassword, oldPassword );
            console.log(response); // Log the response from the API
            setchangePasswordmsg(response.Message)

            if (response.Message =="Password updated successfully") {
                setShowLoginButton(true); // Show login button on success
            }
            // You may handle success or error response here
        } catch (error) {
            console.error('Error changing password:', error);
            // Handle error here
        }
        // setShowChangePassword(false); // Close the popup after password change
    };



    

    return (
        <>
            <div className={styles.main_container}>
                <div className="container">
                    <div className="position-relative d-flex flex-column justify-content-center align-items-center py-3 px-2">
                        <div className="partners-page" style={{ width: '40%' }}>
                            <div className="card">
                                <h4 className="card-header text-uppercase">Change Password</h4>
                                

                                {/* Change Password Popup */}
                                {showChangePassword && (
                                     <Modal show={showChangePassword} onHide={() => setShowChangePassword(false)} centered>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Change Password</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                    <label>Email</label>
                                     <br/>
                                      <input className="form-control" type="email" onChange={(e) => setEmailPassword(e.target.value)} />
                                      <br/>
                                      <label>Temp Password</label>
                                        <br/>
                                        <input className="form-control" type="password" placeholder="Enter Temp Password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
                                       <label>New Password</label>
                                        <br/>
                                        <input className="form-control" type="password" placeholder="Enter New Password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />

                                        <span style={{color:"red"}}>{changePasswordmsg}</span>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={() => setShowChangePassword(false)}>Close</Button>
                                        <Button variant="primary" onClick={handlePasswordChange}>Submit</Button>

                                        {showLoginButton && (
                                            <a href="/landingpage/login">
                                    <button className="btn btn-primary mx-2" type="button">Login</button></a>
                                )}
                                    </Modal.Footer>
                                </Modal>
                                )}

                                

                                 <button className="btn btn-primary mx-2" type="button" onClick={handleChangePassword}>Change Password</button>

                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Partners;

import { useState,useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import styles from "../styles.module.css";
import { Icon } from '@iconify/react';
import { API_BASE_URL } from '../../../config/serverApiConfig';
import { Modal, Button } from 'react-bootstrap';
import * as apiService from '../../../services';

const Login = () => {
	const [data, setData] = useState({ user: "", password: "" });
	const [error, setError] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const navigate = useNavigate();

	const handleChange = ({ currentTarget: input }) => {
		setData({ ...data, [input.name]: input.value });
	};

	const handleTogglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        setError("");
        const url = API_BASE_URL + "/sbdriverlogin";
        const { data: res } = await axios.post(url, data);

        if (res.user_data && res.user_login) {
            // Store user data in localStorage
            const user = res.user_data[0];
            const fullName = `${user.FirstName} ${user.LastName}`;
            const street = user.Street || "";
            const fullAddress = `${street} ${user.CurrentCity} ${user.CurrentState}`;

            localStorage.setItem("token1", res.user_login);
            localStorage.setItem('user1_data', JSON.stringify(user));
            localStorage.setItem("registrationNo", user.companyid);
            localStorage.setItem("userid", user.DriverId);
            localStorage.setItem("useremail", user.DriverEmail);
            localStorage.setItem("driveraddress", fullAddress);
            localStorage.setItem("drivername", fullName);
            localStorage.setItem("driverphone", user.PhoneNumber);
            localStorage.setItem("driverZip",user.ZipCode);

            // Fetch user assessments
            const requestOptions = {
                method: 'POST',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ "userid": user.DriverId }),
                redirect: 'follow'
            };

            const response = await fetch("https://casaahaanahotels.com/get_user_assessments", requestOptions);
            const result = await response.json();

            if (result.Assessments) {
                localStorage.setItem('assessment_id', result.Assessments);
            }else if(result.assessments){
              localStorage.setItem('assessment_id', result.assessments);
            }

            // Redirect to "/landingpage/Assessment"
            window.location = "/landingpage/Assessment";
        } else {
            setError(res.Message);
        }
    } catch (error) {
        if (error.response && error.response.status >= 400 && error.response.status <= 500) {
            setError(error.response.data.Message);
        } else {
            setError("An unexpected error occurred.");
        }
    }
};

  const handleRegister = () => {
        const reg = localStorage.getItem("reg");
        
        navigate('/landingpage/register/'+reg);
        // window.location.reload();
    };
  useEffect(() => {
   // localStorage.removeItem("token");
     const imageFile = localStorage.getItem("patnerImg");
  var existingImg = document.getElementById('bandingImg');

// if (!existingImg) {
//   // If it doesn't exist, create the image and append it
//   var img = document.createElement('img');
//   img.src = 'https://starbehaviors.com/uploadimage/' +imageFile;
//   img.width = 136;
//   img.height = 48;
//   img.alt = 'Star Behaviors Logo';
//   img.id = 'bandingImg'; // Set an ID to identify the image

//   var div = document.getElementById('banding');
//   div.appendChild(img);
// }


if (!existingImg) {
  // If it doesn't exist, create the image and append it
  var img = document.createElement('img');
  var reg = localStorage.getItem("reg");
  img.src = 'https://starbehaviors.com/uploadimage/' + imageFile;
  img.width = 136;
  img.height = 48;
  img.alt = 'Star Behaviors Logo';
  img.id = 'bandingImg'; // Set an ID to identify the image

  // Create an anchor element
  var anchor = document.createElement('a');
  anchor.href = 'https://sb.starbehaviors.com/landingpage/' + reg; // Replace with the actual URL

  // Append the image inside the anchor
  anchor.appendChild(img);

  // Get the parent div and append the anchor with the image
  var div = document.getElementById('banding');
  div.appendChild(anchor);
}

     var div1 = document.getElementById('st');
    var img = document.getElementById('imgId'); // Replace 'imgId' with the actual id of your img element.

    if (div1 && img) {
      div1.removeChild(img); // Remove the 'img' element from 'div1'.
    }


    var div1 = document.getElementById('register');
    var button = document.getElementById('reg'); // Replace 'reg' with the actual id of your button element.

if (div1 && button) {
  div1.removeChild(button); // Remove the existing button element from 'div1'.

  // Create a new button element and set its attributes.
  var newButton = document.createElement('button');
  newButton.className = 'nav-link btn btn-link text-white';
  newButton.textContent = 'Register';
  newButton.id='reg';
  newButton.addEventListener('click', handleRegister); // Assuming handleLogin1 is a defined function.

  // Append the new button to 'div1'.
  div1.appendChild(newButton);
}

  });



  const [showChangePassword, setShowChangePassword] = useState(false);


const [email, setNewemail] = useState('');

  const handleCloseChangePassword = () => {
        setShowChangePassword(false);
    };
      const handleChangePassword = () => {
        setShowChangePassword(true);
    };

   const [changePasswordmsg, setchangePasswordmsg] = useState('');

    const handlePasswordChange = async () => {
        // Call API to change password
        // Example API call (replace with actual API call):
        try {
            const response = await apiService.forgetdriverPassword( email );
            console.log(response); // Log the response from the API
            setchangePasswordmsg(response.Message)

            const sendmail= `<body style="font-family:Arial,sans-serif;background-color:#f4f4f4;margin:0;padding:0"><div class="container" style="width:70%;margin:30px auto;background:#fff;padding:20px;box-shadow:0 0 15px rgba(0,0,0,.2);border-radius:8px;text-align:center"><div class="header" style="margin-bottom:20px"><img src="https://starbehaviors.com/uploadimage/star_behaviors_llc_cover.jpeg" alt="Company Logo" style="width:auto;height:100px;"></div><div class="content" style="text-align:left;line-height:1.6"><h4 style="margin-top:0">Your Password Reset Information</h4><p style="font-size:16px;">Dear ${response.name},</p><p style="font-size:16px;">Your password for Star Behaviors has been reset as requested today. Below are your new login details:<b> <br>Username:</b> ${response.email} ,<br> <b>Temp Password:</b>${response.tempPassword}</p><p style="font-size:16px;"></p><p style="font-size:16px;">Please log in to your Star Behaviors account using the following link:</p><p style="font-size:16px;"><a href="https://sb.starbehaviors.com/landingpage/changepassword" style="color:#06c;text-decoration:none">Star Behaviors Login</a></p><p style="font-size:16px;">The purpose of this email is to make sure you knew your password was reset. If you expected your password to be reset, no action is necessary and you may simply delete this email. If you did not expect your password to be reset (likely by your manager), please contact Star Behaviors immediately at [support@starbehaviours.com] and let us know. <a href="mailto:support@starbehaviour.com" style="color:#06c;text-decoration:none">support@starbehaviour.com</a>.</p><p style="font-size:16px;">Thank you for choosing Star Behaviors!<br>Best regards,</p></div><div class="footer" style="margin-top:20px;font-size:.9em;color:#555">Star Behaviors Support</div></div></body>`;


             const replyTo = encodeURIComponent("assessment@starbehaviors.com");
            const sendTo = encodeURIComponent(email);
            const title = encodeURIComponent('Forget Password');
            const body = encodeURIComponent(sendmail);

            const queryString = `replyTo=${replyTo}&sendto=${sendTo}&title=${title}&body=${body}`;
   

            var xhr = new XMLHttpRequest();
            xhr.open("GET", "https://starbehaviors.com/sbassesmentsendmails.php?" + queryString, true);
            xhr.send();

   
                try {
                  const myHeaders = new Headers();
                  myHeaders.append('Content-Type', 'application/json');

                  const raw = JSON.stringify({
                   
                    semail:email,
                    subject: 'Forget Password',
                    tenstreet_mail: sendmail,
                  });

                  const requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow',
                  };

                  const response = await fetch('https://casaahaanahotels.com/send_mail', requestOptions);
                  const result = await response.text();
                  console.log(result);
                  
                } catch (error) {
                  console.log(error);
                }
            // You may handle success or error response here
        } catch (error) {
            console.error('Error changing password:', error);
            // Handle error here
        }
        // setShowChangePassword(false); // Close the popup after password change
    };


	return (
		<>
			<div className="container">
				<div className="position-relative d-flex flex-column justify-content-center align-items-center py-3 px-2">
					<div className="admin-login-page">
						<div className="card">
							<h4 className="card-header text-uppercase">User Login</h4>
							<div className="card-body">

              {showChangePassword && (
                 <Modal show={showChangePassword} onHide={() => setShowChangePassword(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Forgot Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <label>Email</label>
                 <br/>
                  <input className="form-control" placeholder="Enter Your Email"  type="email"  value={email} onChange={(e) => setNewemail(e.target.value)} />
                 
                 

                    <span style={{color:"red"}}>{changePasswordmsg}</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowChangePassword(false)}>Close</Button>
                    <Button variant="primary" onClick={handlePasswordChange}>Submit</Button>
                </Modal.Footer>
            </Modal>
            )}
								<form onSubmit={handleSubmit}>
									<div className="form-floating mb-3">
										<input
                      id="emailAddress"
                      className="form-control"
                      type="email"
                      name="user"
                      onChange={handleChange}
                      value={data.user}
                      required
                      placeholder="Email Address"
                    />
                    <label htmlFor="emailAddress">Email Address</label>
                  </div>
                  <div className="input-group mb-3">
                    <div className="form-floating flex-fill">
                      <input
                        id="password"
                        className="form-control"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        onChange={handleChange}
                        value={data.password}
                        required
                        placeholder="Password"
                      />
                      <label htmlFor="password">Password</label>
                    </div>
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={handleTogglePasswordVisibility}
                    >
                      <Icon icon={showPassword ? "oi:eye" : "oi:eye"} />
                    </button>
                  </div>
                  <div>
                    {error && <div className={styles.error_msg}>{error}</div>}
                    <button type="submit" className="btn btn-primary">
                      Login
                    </button>

                    <button className="btn btn-primary mx-2" type="button" onClick={handleChangePassword}>Forgot Password</button>
                  </div>
                </form>
              </div>
               <ul className="nav nav-pills nav-justified mt-3">
                <li className="nav-item">
                  <a
                    className="nav-link text-decoration-underline small p-2"
                    href="https://sb.starbehaviors.com/imgs/StarBehaviorsPrivacyPolicy.pdf"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link text-decoration-underline small p-2"
                    href="https://sb.starbehaviors.com/imgs/StarBehaviorsUserAgreement.pdf"
                    target="_blank"
                  >
                    User Agreement
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
